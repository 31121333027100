
import Banner from '~/components/molecules/Banner'

export default {
  name: 'CategoryBackgroundMediaSection',

  components: {
    Banner,
  },

  props: {
    sectionBackgroundMedia: {
      type: Object,
      required: true
    },
    homepageBackgroundVideoMuted: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data() {
    return {
      imageError: false
    }
  },

  computed: {
    categoryPageTitle () {
      if (
        this.sectionBackgroundMedia.richDescription &&
        this.sectionBackgroundMedia.richDescription.input &&
        !this.sectionBackgroundMedia.richDescription.hidden
      ) {
        return this.sectionBackgroundMedia.richDescription.input
      }

      return ''
    },
    categoryPageVideoBackgroundUrl () {
      // See: https://help.vimeo.com/hc/en-us/articles/12426260232977-Player-parameters-overview
      // background=1 is to hide the vimeo controls and sets the video to autoplay and loop when the player loads
      const vimeoParameters = '?background=1&quality=1080p'

      if (
        this.sectionBackgroundMedia.description &&
        this.sectionBackgroundMedia.description.input &&
        !this.sectionBackgroundMedia.description.hidden
      ) {
        return this.sectionBackgroundMedia.description.input + vimeoParameters
      }

      return ''
    },
    tFontSize () {
      return this.$vuetify.breakpoint.mdAndUp ? '40px' : '35px'
    },
  },

  methods: {
    handleImageError() {
      this.imageError = true
    },

    toggleHomepageBackgroundVideoMuted() {
      this.homepageBackgroundVideoMuted = !this.homepageBackgroundVideoMuted
    }
  }
}
