
// eslint-disable-next-line import/no-named-as-default
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Navigation, Pagination, Autoplay])

export default {
  props: {
    cards: {
      type: Array,
      required: true,
    }
  },

  data () {
    return {
      cardsData: [],
    }
  },

  watch: {
    cards: {
      handler() {
        if (this.cards) {
          this.cardsData = this.cards
        }
      },
      immediate: true,
      deep: true
    },
  },

  mounted() {
    // eslint-disable-next-line no-new
    new Swiper('.swiper-container', {
      on: {
        init(swiper) {
          setTimeout(() => {
            swiper.slideTo(0, 200, false)

            swiper.appendSlide([])
          }, 8000)
        }
      },
      slidesPerView: 1,
      effect: 'cards',
      loop: true,
      autoplay: {
        delay: 5000,
      },
      cardsEffect: {
        slideShadows: true
      },
      grabCursor: true,

      centeredSlides: true,
      spaceBetween: 0,
      pagination: {
        el: '.swiper-pagination-carousel',
        clickable: true
      },
      scrollbar: {
        el: '.swiper-scrollbar',
      },
      initialSlide: 1,
      navigation: {
        nextEl: '.swiper-button-next-carousel',
        prevEl: '.swiper-button-prev-carousel',
      },
      breakpoints: {
      }
    })
  }
}
