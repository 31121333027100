
// eslint-disable-next-line import/no-named-as-default
import Swiper, { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Navigation, Pagination])

const sliderOptions = {
  on: {
    init(swiper) {
      setTimeout(() => {
        swiper.slideTo(0, 200, false)

        swiper.appendSlide([])
        swiper.updateSlides()
      }, 8000)
    }
  },
  slidesPerView: 1,
  effect: 'cards',
  cardsEffect: {
    slideShadows: true
  },
  slideClass: 'testimonials-slide',
  grabCursor: true,
  spaceBetween: 25,
  pagination: {
    el: '.swiper-pagination-testimonials',
  },
  navigation: {
    nextEl: '.swiper-button-next-testimonials',
    prevEl: '.swiper-button-prev-testimonials',
  },
  initialSlide: 0,
  scrollbar: {
    el: '.swiper-scrollbar',
  },
  breakpoints: {
    830: {
      slidesPerView: 2
    },
    1227: {
      slidesPerView: 3,
      spaceBetween: 50,
    }
  }
}

export default {
  name: 'CategoryTestimonialsSection',

  props: {
    sectionTestimonialsCarousel: {
      type: Object,
      required: true
    },
  },

  data () {
    return {
      cards: [],
      imageError: false,
    }
  },

  computed: {
  },

  updated() {
    // eslint-disable-next-line no-new
    new Swiper('.swiper-container-testimonials', sliderOptions)
  },

  mounted() {
    // eslint-disable-next-line no-new
    new Swiper('.swiper-container-testimonials', sliderOptions)
  },

  methods: {
    handleImageError() {
      this.imageError = true
    },
  },
}
