
  import Carousel from '../Carousel'

  export default {

    name: 'CategoryInvestmentsSection',

    components: {
      Carousel
    },

    props: {
      sectionCustomInvestment: {
        type: [Object, Boolean],
        required: true
      },
      sectionInvestingForAll: {
        type: [Object, Boolean],
        required: true,
      },
      sectionIndustryRecords: {
        type: [Object || Boolean],
        required: true,
      },
      sectionIndustryRecordsCarousel: {
        type: [Object || Boolean],
        required: true,
      },
    },

    data () {
      return {
        industryRecordsCarousel: null,
        imageError: false
      }
    },

    computed: {},

    watch: {
      sectionIndustryRecordsCarousel: {
        handler() {
          if (this.sectionIndustryRecordsCarousel && this.sectionIndustryRecordsCarousel.link) {
            this.industryRecordsCarousel = this.sectionIndustryRecordsCarousel.link.map(card => ({
              description: card.media && card.media.description ? card.media.description.input : '',
              media: card.media && card.media.media && card.media.enabled && card.media.media.url ? card.media.media.url : '',
            }))
          }
        },
        immediate: true,
        deep: true
      },
    },

    methods: {
      handleImageError () {
        this.imageError = true
      },
    },
  }
