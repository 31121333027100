
import { mapState, mapMutations } from 'vuex'
import HowItWorksModal from '~/components/molecules/HowItWorksModal'

export default {
  name: 'CategoryFAQSection',

  components: {
    HowItWorksModal,
  },

  props: {
    sectionHowItWorks: {
      type: Object,
      required: true
    },
  },

  data () {
    return {
      dialog: false,
      currentLink: null
    }
  },

  computed: {
    linkSize() {
      return this.sectionHowItWorks.link && this.sectionHowItWorks.link.length > 0
    },
    ...mapState('appSettings', [
      'homepageBackgroundVideoMuted'
    ]),
  },

  methods: {
    ...mapMutations('appSettings', [
      'toggleHomepageBackgroundVideoMuted',
    ]),
    showDialog (linkCarousel) {
      this.currentLink = linkCarousel
      this.dialog = true

      // if home page background video is not muted,
      // mute it when dialog is opened
      if (!this.homepageBackgroundVideoMuted) {
        this.toggleHomepageBackgroundVideoMuted()
      }
    },
  },
}
